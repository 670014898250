export const EPGM_URL = 'https://hom.epgm.pgm.fortaleza.ce.gov.br';
export const AGILIS_API_URL = 'https://hom4.apiagilis.pgm.fortaleza.ce.gov.br';
export const AGILIS_FRONT = 'hom4';
export const AGILIS_ACCESS_TOKEN = '2TW5cyxjS-73u1_Fhmay';
export const EPGM_SIGN_URL = '';
export const DEBITBOX_AGILIS_API_URL = 'https://hom.apiagilis.pgm.fortaleza.ce.gov.br';
export const SHOW_AGILIS_DEBITBOX = true;
export const EPGM_LOGIN_SCREEN = false;
export const GOOGLE_ANALYTICS_TOKEN = 'example';
export const DEBITBOX_AGILIS_FRONT_URL = 'https://hom6.agilis.pgm.fortaleza.ce.gov.br/';
export const LOCAL_JAVA_SIGNER_SERVICE = 'http://localhost:50000';
